<template>
  <div class="container">
    <RoleCard role="mentor">
      <template #label>Регистрация школы</template>
      <template #label_info>
        <div v-if="steps.length > 1 && step">
          Шаг {{ steps.indexOf(step) + 1 }}&nbsp;из&nbsp;{{
            steps.length
          }}&nbsp;&mdash;&nbsp;{{ stepsType[step] }}
        </div>
      </template>
      <template #label_img>
        <img src="@/assets/images/reg_mentor.svg" />
      </template>
      <template #body>
        <BaseLoadingBox
          :pending="pending"
          theme="reg"
          min-height="240px">
          <div v-if="step === 'select'">
            <div class="row mb-m">
              <div class="col-md-8 col-xs-12">
                <p>
                  Ниже вы видите организации, администратором которых вы
                  являетесь. Выберите ту из них, которую вы хотите
                  зарегистрировать как Школу НТО, или
                  <a
                    href="#"
                    class="link"
                    @click.prevent="handleCreateNew"
                    >создайте новую</a
                  >.
                </p>
              </div>
            </div>

            <hr class="hr" />

            <div class="row card-list">
              <div
                v-for="item in orgList"
                :key="item.id"
                class="col-md-4 col-sm-6 col-xs-12 card-list__item mb-m">
                <div
                  :organization="item"
                  class="org-item"
                  @click="handleSelect(item)">
                  <Corners />
                  <div class="org-item__title">
                    {{ item.full_name || item.short_name }}
                  </div>
                  <div class="org-item__info mb-m">
                    <div
                      v-if="item.address"
                      class="mb-s">
                      {{ item.address }}
                    </div>
                    <div
                      v-if="item._requirements.length"
                      class="org-item__requirements">
                      <div
                        v-for="req in item._requirements"
                        :key="req">
                        - {{ req }}
                      </div>
                    </div>
                  </div>
                  <div class="org-item__control">
                    <BaseButton
                      block
                      theme="reg"
                      >Выбрать эту школу</BaseButton
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-xs-12 card-list__item mb-m">
                <div
                  class="org-new"
                  @click.prevent="handleCreateNew">
                  <Corners class="org-new__corners" />
                  Добавить школу
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="step === 'create'">
            <OrganizationForm
              :allow-cancel="orgList.length > 0"
              @onSubmit="handleSetOrg"
              @cancel="goToSelect" />
          </div>
          <div v-else-if="step === 'legal'">
            <LegalEntityForm
              :organization="selectedOrg"
              @cancel="goToSelect"
              @onSubmit="handleSetLegal" />
          </div>
          <div v-else>
            <div
              v-if="error"
              class="form-error">
              {{ error }}
            </div>
          </div>
        </BaseLoadingBox>
      </template>
    </RoleCard>
  </div>
</template>

<script>
import OrganizationForm from "@/components/principal/OrganizationForm";
import LegalEntityForm from "@/components/principal/LegalEntityForm";
import Corners from "@/components/Corners";
import { request, talentRequest } from "@/services/api";
import RoleCard from "@/components/registration/RoleCard";
import * as Sentry from "@sentry/vue";
import findIndex from "lodash/findIndex";
import {
  MENTOR_ROLE,
  PRINCIPAL_CODE_KEY,
  PRINCIPAL_ORG_KEY,
  SECONDARY_EDUCATION_ALIAS,
} from "@/constants";

export default {
  name: "PrincipalRegistration",
  components: { OrganizationForm, LegalEntityForm, RoleCard, Corners },
  metaInfo() {
    return {
      title: "Директорам школ. Регистрация школы.",
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.query[PRINCIPAL_CODE_KEY] && to.query[PRINCIPAL_ORG_KEY]) {
      return next();
    }
    next({
      name: "principal_error",
    });
  },
  data() {
    return {
      orgListPending: true,
      step: null,
      steps: [],
      selectedId: null,
      pending: false,
      error: "",
      organizations: [],
    };
  },
  computed: {
    stepsType() {
      return {
        legal: "Данные о юр. лице",
        create: "Создание организации",
        edu_type: "Тип образовательного учреждения",
      };
    },
    talentUser() {
      return this.$store.state.user.talentUser;
    },
    orgList() {
      return this.organizations.map((org) => {
        const result = { ...org };
        result._requirements = [];
        if (!org.legal_entity) {
          result._requirements.push(
            "Необходимо заполнить  данные о юр. лице школы"
          );
        }
        return result;
      });
    },
    selectedOrg() {
      const { selectedId } = this;
      if (!selectedId) return;
      return this.orgList.find((n) => n.id === selectedId);
    },
  },
  async created() {
    const isParticipant = this.$store.getters["user/isParticipant"];
    if (isParticipant) {
      this.$router
        .push({
          name: "user-index",
        })
        .catch((error) => {
          console.log("error", error);
        });
      return;
    }
    if (!this.talentUser) {
      this.$router
        .push({
          name: "principal",
          query: this.$route.query,
        })
        .catch((error) => {
          console.log("error", error);
        });
      return;
    }
    this.pending = true;
    await this.getOrganizations();
    if (this.orgList.length) {
      // Редиректим на страницу директора если орагнизация уже подключена
      const connectedOrg = this.orgList.find((org) => {
        return org.representatives?.some(
          (n) => n === +this.$route.query[PRINCIPAL_ORG_KEY]
        );
      });
      if (connectedOrg) {
        this.$router
          .push({ name: "principal", query: this.$route.query })
          .catch((error) => {
            console.log("error", error);
          });
        return;
      }
      this.goToSelect();
    } else {
      this.handleCreateNew();
    }
    this.pending = false;
  },
  methods: {
    handleOrgUpdate(payload) {
      const { id, ...rest } = payload;
      const idx = findIndex(this.organizations, (n) => n.id === id);
      if (idx >= 0) {
        const newData = {
          ...this.organizations[idx],
          ...rest,
        };
        this.organizations.splice(idx, 1, newData);
      }
    },
    handleCreateNew() {
      this.steps = ["create", "legal"];
      this.setStep("create");
    },
    goToSelect() {
      this.steps = [];
      this.selectedId = null;
      this.setStep("select");
    },
    setStep(step) {
      this.step = step;
    },
    handleSetOrg(organization) {
      this.organizations.push(organization);
      this.selectedId = organization.id;
      this.step = "legal";
    },
    async handleSelect(org) {
      this.selectedId = org.id;
      const steps = [];
      if (!org.legal_entity) {
        steps.push("legal");
      }
      if (!steps.length) {
        return this.goNext();
      }
      this.steps = steps;
      this.step = steps[0];
    },
    async handleSetLegal(legalId) {
      this.handleOrgUpdate({
        id: this.selectedId,
        legal_entity: legalId,
      });
      this.goNext();
    },
    async getOrganizations() {
      try {
        const { data } = await talentRequest({
          url: `/api/users/${this.talentUser.id}/organizations/`,
          params: {
            limit: 50,
            offset: 0,
            is_owner: "True",
          },
        });
        this.organizations = data.results;
      } catch (error) {
        console.log("error.message", error.message);
      }
      this.orgListPending = false;
    },
    async goNext() {
      const org = this.selectedOrg;
      try {
        if (!org) {
          throw { message: "Необходимо выбрать организацию" };
        } else if (!org.legal_entity) {
          throw { message: "Необходимо заполнить данные о юр.лице" };
        }
        /**
         * Проставляем статус среднего образовательного учреждения
         * и устанавливаем связь с рег представителем по инвайт коду
         */
        const requests = [
          talentRequest({
            method: "PATCH",
            url: `/api/users/${this.talentUser.id}/organizations/${this.selectedOrg.id}/`,
            data: {
              educational_category: SECONDARY_EDUCATION_ALIAS,
            },
          }),
          talentRequest({
            method: "POST",
            url: `/api/users/${this.talentUser.id}/organizations/${this.selectedOrg.id}/representative/invite/`,
            params: {
              code: this.$route.query[PRINCIPAL_CODE_KEY],
              representative_id: +this.$route.query[PRINCIPAL_ORG_KEY],
            },
          }),
        ];
        await Promise.all(requests);

        this.handleOrgUpdate({
          id: this.selectedOrg.id,
          educational_category: SECONDARY_EDUCATION_ALIAS,
          representatives: [
            ...this.selectedOrg.representatives,
            +this.$route.query[PRINCIPAL_ORG_KEY],
          ],
        });

        // Если директор не зареган еще на онти как наставник - то регаем
        if (this.$store.getters["user/user"]?.role !== MENTOR_ROLE) {
          try {
            await request({
              method: "POST",
              url: "/user/register",
              data: {
                role: MENTOR_ROLE,
              },
            });
            await this.$store.dispatch("user/getMe");
          } catch (error) {
            // do nothing
          }
        }
        this.$router.push({ name: "principal", query: this.$route.query });
      } catch (error) {
        this.error = error.message;
        this.step = "error";
        Sentry.captureException(error, {
          tags: {
            error_group: "principal",
          },
        });
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.org-item {
  padding: 20px;
  min-height: 100%;
  background-color: @principal-blue;
  color: #fff;
  transition: background 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &__title {
    line-height: 1.32;
    margin-bottom: 10px;
    flex-grow: 0;
  }

  &__info {
    font-size: 13px;
    flex-grow: 1;
  }

  &__requirements {
    color: @white-blue;
  }

  &__control {
    flex-grow: 0;
  }

  &:hover {
    background-color: darken(@principal-blue, 5%);
  }
}
.org-title {
  margin-bottom: 20px;
  font-size: 18px;
}
.org-new {
  padding: 20px;
  border: 1px solid @lp-outline-color;
  min-height: 100%;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  background-color: transparent;
  color: lighten(@lp-outline-color, 15%);
  transition: color 0.3s, background 0.3s, border 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &__corners {
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
  }

  &:hover {
    background-color: @principal-blue;
    border-color: @principal-blue;
    color: #fff;
    cursor: pointer;
  }

  &:hover &__corners {
    opacity: 1;
  }
}
</style>
